<template>
    <div class="welcon">
        <div class="bgk1">
            <img src="../../assets/imgs/bg1.png" alt="">
        </div>
        <div class="bgk2">
            <img src="../../assets/imgs/bg2.png" alt="">
        </div>
        <div class="bgk3">
            <img src="../../assets/imgs/bg3.png" alt="">
        </div>
        <div class="logo">
            <img src="../../assets/imgs/logo.jpg" alt="">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.welcon {
    width: 100%;
    height: 95%;
    background: url(../../assets/imgs/bg4.png) no-repeat center;
    background-size: 100% 100%;
    position: relative;
    left: 0;
    top: 90px;
    // bottom: 0;
    .bgk1 {
        width: 35%;
        height: 40%;
        position: absolute;
        left: 6%;
        top: 38%;
        z-index: 100;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .bgk2 {
        width: 25%;
        height: 60%;
        position: absolute;
        left: 65%;
        top: 40%;
        z-index: 50;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .bgk3 {
        width: 90%;
        height: 65%;
        position: absolute;
        left: 4%;
        top: 18%;
        z-index: 10;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .logo {
        // background: pink;
        width: 174px;
        height: 78px;
        position: absolute;
        left: 150px;
        top: -82px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>